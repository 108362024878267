var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "consu-container" },
    [
      _c("v-consultation-header"),
      _c("div", { staticClass: "wrap-content" }, [
        _c("ul", { staticClass: "wrap" }, [
          _c(
            "li",
            { staticClass: "bordered" },
            [
              _c("h1", [_vm._v("舌照")]),
              _c("div", { staticClass: "pic-text" }, [
                _vm._v("请上传舌苔和舌底的清晰照片"),
              ]),
              _c(
                "van-uploader",
                {
                  staticClass: "pic-uploader",
                  attrs: {
                    "before-read": _vm.beforeRead,
                    accept: "image/*",
                    "after-read": _vm.afterRead,
                    "max-count": 5,
                  },
                  on: { delete: _vm.deleteFile1 },
                  model: {
                    value: _vm.fileList1,
                    callback: function ($$v) {
                      _vm.fileList1 = $$v
                    },
                    expression: "fileList1",
                  },
                },
                [_c("div", { staticClass: "upload-pic-icon" })]
              ),
              _c("div", { staticClass: "pic-tips" }, [
                _vm._v("*请在光线充足的室内，使用后置摄像头拍摄"),
              ]),
              _c("div", { staticClass: "pic-tips" }, [
                _vm._v("*在距离面部约20cm处（一前臂距离）拍摄舌照"),
              ]),
              _c("div", { staticClass: "pic-tips" }, [
                _vm._v("*拍摄时关闭美颜功能，注意对焦舌头"),
              ]),
              _c("div", { staticClass: "pic-tips" }, [
                _vm._v("*拍舌苔时，舌头向下伸长；拍舌底时，舌头向上抬起"),
              ]),
              _vm._m(0),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "bordered" },
            [
              _c("h1", [_vm._v("面照")]),
              _c("div", { staticClass: "pic-text" }, [
                _vm._v("请上传正面素颜彩色照片，如需要也可拍摄其它患部照片"),
              ]),
              _c(
                "van-uploader",
                {
                  staticClass: "pic-uploader",
                  attrs: {
                    "before-read": _vm.beforeRead,
                    accept: "image/*",
                    "after-read": _vm.afterRead,
                    "max-count": 5,
                  },
                  on: { delete: _vm.deleteFile2 },
                  model: {
                    value: _vm.fileList2,
                    callback: function ($$v) {
                      _vm.fileList2 = $$v
                    },
                    expression: "fileList2",
                  },
                },
                [_c("div", { staticClass: "upload-pic-icon" })]
              ),
              _c("div", { staticClass: "pic-tips" }, [
                _vm._v("*请在自然光线下拍摄面部正面素颜照"),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "page-bottom" }, [
        _c("div", { staticClass: "btn1", on: { click: _vm.gotoNext } }, [
          _c("span", [_vm._v("跳过该步骤")]),
        ]),
        _c(
          "div",
          {
            staticClass: "btn2",
            class: { disabled: !_vm.canNext },
            on: { click: _vm.gotoSubmit },
          },
          [_c("span", [_vm._v("去提交")])]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pic-example-list" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/pic-example1.png") },
      }),
      _c("img", {
        attrs: { src: require("../../assets/images/pic-example2.png") },
      }),
      _c("img", {
        attrs: { src: require("../../assets/images/pic-example3.png") },
      }),
      _c("img", {
        attrs: { src: require("../../assets/images/pic-example4.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }